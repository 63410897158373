import React, { useState } from "react";
import { menuData } from "../../menuData";
import { StyledGrid, StyledHolder } from "../../styled/types";
import { Image, SuperLink } from "../elements";

interface MenuElement {
  src: string;
  to: string;
  gif: string;
  t: string;
  l: string;
  w?: string;
  h?: string;
}

interface MenuProps {
  marketLink?: string;
  closeScene: Function;
}

export default function Menu(props: MenuProps): JSX.Element {
  const [activeMenu, setActiveMenu]: [string | null, Function] = useState(null);

  const returnMenu = (menuData: MenuElement[]): JSX.Element[] | undefined => {
    if (!menuData || !Array.isArray(menuData)) return;

    return menuData
      .filter((el) => props.marketLink || !el.to.includes("market"))
      .map(
        (
          {
            src = "",
            to = "",
            gif = "",
            t = "",
            l = "",
            w = "1920",
            h = "1080",
          }: MenuElement,
          i
        ): JSX.Element => {
          return (
            <StyledHolder
              key={i}
              position="relative"
              onMouseEnter={() => setActiveMenu(to)}
              onMouseLeave={() => setActiveMenu(null)}
            >
              <Image
                w={w}
                h={h}
                t={t}
                l={l}
                src={activeMenu === to ? gif : ""}
                noEvents={true}
              />
              {to.includes("market") ? (
                <a href={props.marketLink} target="_blank" rel="noreferrer">
                  <Image w="75" h="74" position="relative" src={src} />
                </a>
              ) : (
                <SuperLink to={to} closeScene={props.closeScene}>
                  <Image w="75" h="74" position="relative" src={src} />
                </SuperLink>
              )}
            </StyledHolder>
          );
        }
      );
  };

  return (
    <StyledHolder
      position="absolute"
      h="74"
      mt="370"
      t="50%"
      l="0"
      w="100%"
      textAlign="center"
    >
      <StyledGrid
        flow="column"
        className="menu"
        cols="auto"
        rows="7.4rem"
        gap="40"
        inline={true}
        w="auto"
      >
        {returnMenu(menuData)}
      </StyledGrid>
    </StyledHolder>
  );
}
