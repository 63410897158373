import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyledHolder, StyledGrid, StyledFlex } from "../styled/types";
import { Image, BackButton, SuperLink } from "../components/elements";
import styled from "styled-components";
import { Logo } from "../store/actions";

const SponsorsPage = styled.div`
  padding: 17.5rem 0;
  display: block !important;
`;

interface SponsorsProps { 
    logo?: string,
    heading?: string,
    sponsors_text?: string,
    partners_text?: string,
    sponsors_logos?: Logo[],
    partners_logos?: Logo[],
    closeScene: Function,
    pageReady: Function
}

class Sponsors extends Component<SponsorsProps> {

    componentDidMount(): void {
        this.props.pageReady && this.props.pageReady();
    }

    returnLogos(logos: Logo[] | undefined): JSX.Element[] | undefined {
        if (!logos || !Array.isArray(logos)) return;

        return logos.map(({logo = "", link = ""}: Logo, i) => (
                <a key={i} href={link} target="_blank" rel="noreferrer">
                    <Image  src={logo}  maxw="200" maxh="120" position="relative" contain={true} />
                </a>
            ));
    }

    render() {

        const { logo = "", heading = "", sponsors_text = "", 
            partners_text = "", sponsors_logos, partners_logos } = this.props;

        return (
        <SponsorsPage className="page yellow">
                <BackButton closeScene={this.props.closeScene} />
                <StyledHolder position="relative" mb="100">
                    <Image position="static" src={heading} w="1920" h="166" />
                    <SuperLink closeScene={this.props.closeScene} to="/">
                        <Image src={logo} t="-132" l="700" w="500" h="246" />
                    </SuperLink>
                </StyledHolder>
                <StyledFlex dir="column" align="center">
                    <Image src={sponsors_text} position="static" mb="45" w="235" h="56" />
                    <StyledGrid flow="column" cols="auto" rows="auto" gap="80" inline={true} mb="100">
                        {this.returnLogos(sponsors_logos)}
                    </StyledGrid>
                    <Image src={partners_text} position="static" t="-132" mb="45" l="700" w="258" h="54" />
                    <StyledGrid flow="column" cols="auto" rows="auto" gap="80" inline={true}>
                        {this.returnLogos(partners_logos)}
                    </StyledGrid>
                </StyledFlex>
        </SponsorsPage>
        )
    }
};

const mapStateToProps = ({ store }: any): { store: SponsorsProps | {} } => store.sponsors || {}

export default connect(mapStateToProps)(Sponsors);