import React from "react";
import { Image, SuperLink } from "../elements";
import styled from "styled-components";

const StyledBack = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
`;

interface CloseProps {
  closeScene: Function;
}

export default function Close(props: CloseProps) {
  return (
    <StyledBack>
      <SuperLink goBack={true} closeScene={props.closeScene}>
        <Image
          filterHover={true}
          w="300"
          h="258"
          src="./assets/images/backButton.png"
        />
      </SuperLink>
    </StyledBack>
  );
}
