export const menuData = [
  {
    src: "./assets/images/fullMovieIcon.png",
    gif: "./assets/images/video_hover_open_gif.gif",
    to: "/video",
    l: "-630",
    t: "-970",
  },
  {
    src: "./assets/images/galleryIcon.png",
    gif: "./assets/images/fotogalerija_hover_open_gif.gif",
    to: "/gallery",
    l: "-740",
    t: "-960",
  },
  {
    src: "./assets/images/sponsorsIcon.png",
    gif: "./assets/images/gamyba_remeai_scena.gif",
    to: "/sponsors",
    l: "-615",
    t: "-580",
    w: "1210",
    h: "1080",
  },
  {
    src: "./assets/images/contactsIcon.png",
    gif: "./assets/images/kontaktai_hover_open_gif.gif",
    to: "/contacts",
    l: "-980",
    t: "-960",
  },
  {
    src: "./assets/images/marketIcon.png",
    gif: "./assets/images/vietinisgidas_hover.gif",
    to: "/market",
    l: "-385",
    t: "-585",
    w: "1230",
    h: "1080",
  },
  {
    src: "./assets/images/teamIcon.png",
    gif: "./assets/images/komanda_hover_open_gif.gif",
    to: "/team",
    l: "-1220",
    t: "-980",
  },
];
