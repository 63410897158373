import React from 'react';
import { Image } from "../elements";
import { StyledModal } from "../../styled";

interface OldGuyModalProps {
    data: [string, string, string, string] | [],
    redirectToMap: Function
}

export default function OldGuyModal(props: OldGuyModalProps): JSX.Element {
    const { data = [], redirectToMap } = props;

    return (
        <StyledModal className="modal">
            <Image w="1283" h="861" className="pop" src="./assets/images/red_pop_background.png" />
            <Image className="bottom" w="776" h="732" mt="-284" t="50%" l="330" src="./assets/images/old_head.png" />
            <Image className="left" w="703" h="58" mt="-340" t="50%" r="365" src={data[0] || ""} />
            <Image className="right" w="548" h="181" mt="-230" t="50%" r="395" src={data[1] || ""} />
            <Image className="left" w="508" h="113" mt="-10" t="50%" r="405" src={data[2] || ""} />
            <Image opacityHover={true} className="link right" onClick={() => {if (redirectToMap) redirectToMap()}} w="251" h="41" mt="190" t="50%" r="535" src={data[3] || ""} />
        </StyledModal>
    )
}
