import React from "react";
import { StyledHolder, HolderStyles } from "../../styled/types";
import YouTube from "react-youtube-embed";
import { Image } from "./";
import styled from "styled-components";

const StyledPlayer = styled(StyledHolder)`
  .play {
    cursor: pointer;
    transition: opacity 400ms;
    will-change: opacity;

    &.active {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

interface PlayerProps extends HolderStyles {
  video?: string;
  small?: boolean;
  startOutsidePlayer?: Function;
  currentlyPlaying?: string;
}

export default function Player(props: PlayerProps) {
  const { video = "", small } = props;

  return (
    <StyledPlayer {...props}>
      {small ? (
        <>
          <Image
            w="665"
            h="378"
            position="relative"
            src="./assets/images/videoBorderSmall.png"
          />
          <StyledHolder w="605" h="340" t="20" l="30" position="absolute">
            {video && <YouTube id={video} appendSrc={`?playlist=${video}`} />}
          </StyledHolder>
        </>
      ) : (
        <>{video && <YouTube id={video} appendSrc={`?playlist=${video}`} />}</>
      )}
    </StyledPlayer>
  );
}
