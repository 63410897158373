import React, { Component } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Image, SuperLink } from "../components/elements";
import { AspectContainer, mobile } from "../styled";
import { StyledHolder } from "../styled/types";
import { PopupTypes, PopupData } from "../components/blocks/Popup";
import { Redirect } from "react-router-dom";
import { HeaderInterface } from "../store/actions/data_actions";
import { Header, Menu } from "../components/blocks";

const HomePage = styled.div`
  .hero {
    cursor: pointer;
  }
  .bubble {
    transition: opacity 1000ms 500ms;
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
  .hovered {
    transition: opacity 200ms;
    opacity: 0;
  }
  ${mobile(css`
    .logo {
      margin-top: -0.5rem;
    }
  `)}
`;

interface HomeProps {
  logo?: string;
  baloon_1?: string;
  baloon_2?: string;
  baloon_1_out?: string;
  baloon_2_out?: string;
  old_guy_table_texts?: [string, string, string, string];
  sphynx_guy_table_texts?: [string, string, string, string];
  lang?: string;
  redirectToMap: boolean;
  closeScene: Function;
  openPop: (type: PopupTypes, data: PopupData) => void;
  pageReady: Function;
  headerData?: HeaderInterface;
  marketLink?: string;
}

let timer1: ReturnType<typeof setTimeout> | undefined;

class Home extends Component<HomeProps> {
  state = {
    redirect: false,
    oldGuyHovered: false,
    sphynxHovered: false,
  };

  componentWillUnmount(): void {
    clearTimeout(timer1);
  }

  componentDidUpdate(): void {
    if (this.props.redirectToMap) {
      this.props.closeScene && this.props.closeScene();
      timer1 = setTimeout(() => this.setState({ redirect: true }), 400);
    }
  }

  render() {
    const {
      logo = "",
      baloon_1 = "",
      baloon_2 = "",
      baloon_1_out = "",
      baloon_2_out = "",
      old_guy_table_texts,
      sphynx_guy_table_texts,
      lang = "",
      openPop,
      headerData,
    } = this.props;

    if (this.state.redirect) return <Redirect push to="/map" />;

    return (
      <HomePage className="page black">
        <Header lang={lang} headerData={headerData} />
        <AspectContainer>
          <Image
            detectLoad={() => this.props.pageReady && this.props.pageReady()}
            src="./assets/images/mainBackground.jpg"
            w="1920"
            b="0"
          />
          <Image
            className="logo"
            src={logo}
            t="-70"
            l="50"
            w="1728"
            h="972"
            noEvents={true}
          />
          <SuperLink closeScene={this.props.closeScene} to="/">
            <StyledHolder t="67" l="661" w="613" h="390" position="absolute" />
          </SuperLink>
          <StyledHolder
            className="hero"
            position="absolute"
            l="575"
            b="230"
            onMouseEnter={() => this.setState({ oldGuyHovered: true })}
            onMouseLeave={() => this.setState({ oldGuyHovered: false })}
          >
            <Image
              className={this.state.oldGuyHovered ? " hovered" : ""}
              src="./assets/images/old_guy.png"
              w="229"
              h="308"
              position="relative"
              onClick={() =>
                openPop(PopupTypes.oldGuy, { old_guy_table_texts })
              }
            />
            <Image
              noEvents={true}
              className={this.state.oldGuyHovered ? "" : "no_src"}
              src={
                this.state.oldGuyHovered
                  ? "./assets/images/snipka_hover_animation.gif"
                  : ""
              }
              t="50%"
              l="-569"
              w="1920"
              h="1080"
              mt="-698"
            />
            <Image
              noEvents={true}
              className={this.state.oldGuyHovered ? "" : "no_src"}
              src={this.state.oldGuyHovered ? baloon_1 : ""}
              w="534"
              h="253"
              t="-190"
              l="-340"
            />
            <Image
              className={
                "bubble" + (this.state.oldGuyHovered ? " active no_src" : "")
              }
              noEvents={true}
              src={!this.state.oldGuyHovered ? baloon_1_out : ""}
              w="534"
              h="253"
              t="-190"
              l="-340"
            />
          </StyledHolder>
          <StyledHolder
            className="hero"
            position="absolute"
            r="230"
            b="160"
            onMouseEnter={() => this.setState({ sphynxHovered: true })}
            onMouseLeave={() => this.setState({ sphynxHovered: false })}
          >
            <Image
              className={this.state.sphynxHovered ? " hovered" : ""}
              src="./assets/images/sphynx.png"
              w="452"
              h="326"
              position="relative"
              onClick={() =>
                openPop(PopupTypes.sphynx, { sphynx_guy_table_texts })
              }
            />
            <Image
              noEvents={true}
              className={this.state.sphynxHovered ? "" : "no_src"}
              src={
                this.state.sphynxHovered
                  ? "./assets/images/sfinks_hover_animation.gif"
                  : ""
              }
              t="50%"
              l="-1160"
              w="1798"
              h="1011"
              mt="-700"
            />
            <Image
              noEvents={true}
              className={this.state.sphynxHovered ? "" : "no_src"}
              src={this.state.sphynxHovered ? baloon_2 : ""}
              w="534"
              h="253"
              t="-190"
              l="200"
            />
            <Image
              className={
                "bubble" + (this.state.sphynxHovered ? " active no_src" : "")
              }
              noEvents={true}
              src={!this.state.sphynxHovered ? baloon_2_out : ""}
              w="534"
              h="253"
              t="-190"
              l="200"
            />
          </StyledHolder>
          <Image
            src="./assets/images/sveiki.png"
            t="50%"
            l="791"
            w="538"
            h="183"
            mt="31"
          />
          <Menu
            closeScene={this.props.closeScene}
            marketLink={this.props.marketLink}
          />
          <Image
            src="./assets/images/COPYRIGHT.png"
            t="50%"
            l="1471"
            w="378"
            h="183"
            mt="351"
          />
        </AspectContainer>
      </HomePage>
    );
  }
}

const mapStateToProps = ({ store }: any): { store: HomeProps | {} } =>
  store.main || {};

export default connect(mapStateToProps)(Home);
