import React, { useState, useEffect } from 'react';
import { Image, Close } from "../elements";
import { StyledModal, mobile } from "../../styled";
import { Object } from "../../store/actions";
import styled, { css } from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import { StyledHolder } from '../../styled/types';

const Modal = styled(StyledModal)`

    ${mobile(css`
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 58rem 0 20rem;
        -webkit-overflow-scrolling: touch;

        .holder {
            margin: auto;
        }
        .picture {
            margin-top: 0;
            left: 10rem;
            top: -29rem;
            width: 121rem;
            height: 71.2rem;
        }
        .right {
            left: 50%;
            margin-left: -38.2rem;
            margin-top: -41rem;
            width: 68.4rem;
            height: 12.3rem;
        }
        .close {
            top: 12.8rem;
            right: 27.1rem;
            left: auto;

            > * {
                width: 18.4rem;
                height: 37.5rem;
            }
        }
    `)}
`

const Text = styled.div`
    width: 45rem;
    height: 40rem;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    left: 9rem;
    top: 50%;
    margin-top: -19rem;

    ${mobile(css`
        width: 103rem;
        left: 21rem;
        font-size: 5rem;
        top: 106rem;
        margin-top: 0;
    `)}
`;

interface TextModalProps {
    data?: Object,
    closePop: Function
}

let debouncer: ReturnType<typeof setTimeout> | undefined;

export default function TextModal(props: TextModalProps): JSX.Element {
    const { data = {} }: TextModalProps = props;
    const { image = "", text = "", name = "", frame_desktop = "", frame_mobile = "" } = data;

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(debouncer);
        }
    }, []);

    const handleResize = (): void => {
        clearTimeout(debouncer);
        debouncer = setTimeout(() => {
            checkIfMobile();
        }, 300);
    }

    const checkIfMobile = (): void => {
        if (window.innerWidth > 1000) {
            setIsMobile(false);
        } else setIsMobile(true);
    }

    return (
        <Modal className="modal">
            <StyledHolder className="holder" position="relative" w={isMobile ? "1490" : "1451"} h={isMobile ? "2510" : "809"}>
                {isMobile ? (
                    <Image 
                        w="1451" 
                        h="809" 
                        className="pop" 
                        src={frame_mobile || "./assets/images/textBackgroundMobile.png"}
                    />
                ) : (
                    <Image 
                        w="1451" 
                        h="809" 
                        className="pop" 
                        src={frame_desktop || "./assets/images/textBackground.png"}
                    />
                )}
                <Image w="839" h="512" className="left picture" t="50%" l="650" mt="-440" src={image} cover={true} border={true} />
                <Image w="444" h="73" className="right" t="50%" l="80" mt="-330" src={name} />
                <Text className="bottom">{ReactHtmlParser(text)}</Text>
                <Close className="close" t="50%" l="1430" mt="-410" onClick={props.closePop} />
            </StyledHolder>
        </Modal>
    )
}
