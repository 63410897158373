import { createGlobalStyle } from "styled-components";
import { mobile, desktop } from "./";
import styled, { css } from "styled-components";

const GlobalStyle = createGlobalStyle`
    /* :root {
        --grey: #f2f2f2;
    } */
    /* @font-face {
        font-family: 'Noway';
        src: url('/static/fonts/Noway.eot?#iefix') format('embedded-opentype'),  url('/static/fonts/Noway.otf')  format('opentype'),
            url('/static/fonts/Noway.woff') format('woff'), url('/static/fonts/Noway.ttf')  format('truetype'), url('/static/fonts/Noway.svg#Noway') format('svg');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter-Regular';
        src: url('/static/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),  url('/static/fonts/Inter-Regular.woff') format('woff'), url('/static/fonts/Inter-Regular.ttf')  format('truetype'), url('/static/fonts/Inter-Regular.svg#Inter-Regular') format('svg');
        font-weight: normal;
        font-style: normal;
    } */
    html {
        font-size: 0.523vw;
    }
    html, 
    body {
        box-sizing: border-box;
        margin: 0;
	    padding: 0;
    }
    body {
        font-size: 14px;
        color: #000;
        /* font-family: 'Inter-Regular'; */
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #000;
    }
    html.open,
    body.open {
        overflow: hidden;

        main {
            pointer-events: none;
        }
    }
    .page {
        position: relative;
        min-height: 100vh;
        background-color: #000;
        display: flex;
        align-items: center;
        max-width: 100vw;

        &.grey {
            background-color: #e0dec8;
        }
        &.yellow {
            background-color: #fec612;
        }
        &.brown {
            background-color: #e0dec8;
        }
        &.red {
            background-color: #db5c33;
        }
    }
    main {
        position: relative;
        z-index: 2;
    }
    *::-moz-selection {
        background: rgba(218, 92, 50, 0.4);
    }
    *::selection {
        background: rgba(218, 92, 50, 0.4);
    }
    * {
        flex-shrink: 0;
        box-sizing: border-box;

        &:before,
        &:after {
            flex-shrink: 0;
            box-sizing: border-box;
        }
        &:focus {
            outline: none;
        }
    }
    a {
        text-decoration: none;
        color: #000;
    }
    img {
        display: block;
    }
    button {
        background: none;
        box-shadow: none;
        border: none;
        appearance: none;
        padding: 0;
        webkit-appearance: none;
    }
    input {
        border: none;
        border-radius: 0;
        -webkit-appearance: none;
        background: none;
        box-shadow: none;
        outline: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    .clear {
        clear: both;
        width: 100%;
    }
    .desktop_only {
        ${mobile(css`
            display: none;
        `)}
    }
    .mobile_only {
        ${desktop(css`
            display: none;
        `)}
    }
    .no_src {
        visibility: hidden;
    }
`;

export default GlobalStyle;