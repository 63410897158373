import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyledHolder, StyledFlex, StyledGrid } from "../styled/types";
import { Image, BackButton, SuperLink } from "../components/elements";
import styled from "styled-components";

interface TeamProps {
    logo?: string,
    heading?: string,
    team_name?: string,
    members?: string[],
    closeScene: Function,
    pageReady: Function
}

const TeamPage = styled.div`
  padding: 17.5rem 0;
  display: block !important;
`;

class Team extends Component<TeamProps> {

    componentDidMount(): void {
        this.props.pageReady && this.props.pageReady();
    }

    returnMembers(members: string[]): JSX.Element[] {
        return members.map((member = "", i) => 
            <Image key={i} src={member} position="static" contain={true} w="280" h="auto" />
        );
    }

    render() {

        const { 
            logo = "",
            heading = "",
            team_name = "",
            members = []
        } = this.props;

        return (
        <TeamPage className="page red">
                <BackButton closeScene={this.props.closeScene} />
                <StyledHolder position="relative" mb="100">
                    <Image position="static" src={heading} w="1920" h="166" />
                    <SuperLink closeScene={this.props.closeScene} to="/">
                        <Image src={logo} t="-132" l="700" w="500" h="246" />
                    </SuperLink>
                </StyledHolder>
                <StyledFlex dir="column" align="center">
                    <Image src={team_name} position="static" h="54" contain={true} mb="90" />
                    <StyledGrid  
                        cols={members.length < 5 ? `repeat(${members.length}, minmax(0, 1fr))` : "repeat(5, minmax(0, 1fr))"} 
                        rows="auto" gap="85" inline={true}>
                        {this.returnMembers(members)}
                    </StyledGrid>
                </StyledFlex>
        </TeamPage>
        )
    }
};

const mapStateToProps = ({ store }: any): { store: TeamProps | {} } => store.team || {}

export default connect(mapStateToProps)(Team);