export const imagesData = [
    {
      link: "./assets/images/dog.png",
      gif: "./assets/images/icon_suo_hover.gif",
      w: "85",
      h: "68",
      mt: "-210",
      l: "630",
      id: "dog",
      gifW: "85",
      gifH: "68",
      gifT: "-5",
      gifL: "0"
    },
    {
      link: "./assets/images/crown.png",
      gif: "./assets/images/icon_ugnis_hover.gif",
      w: "77",
      h: "69",
      mt: "-274",
      l: "830",
      id: "crown",
      gifW: "85",
      gifH: "68",
      gifT: "-5",
      gifL: "-5"
    },
    {
      link: "./assets/images/bird_in_chimney.png",
      gif: "./assets/images/icon_pigeon_hover.gif",
      w: "57",
      h: "74",
      mt: "-53",
      l: "643",
      id: "bird_in_chimney",
      gifW: "85",
      gifH: "68",
      gifT: "-5",
      gifL: "-12"
    },
    {
      link: "./assets/images/bard.png",
      gif: "./assets/images/icon_ignas_hover.gif",
      w: "76",
      h: "79",
      mt: "-225",
      l: "962",
      id: "bard",
      gifW: "85",
      gifH: "68",
      gifT: "-4",
      gifL: "-2"
    },
    {
      link: "./assets/images/wc.png",
      gif: "./assets/images/icon_wc_hover.gif",
      w: "70",
      h: "80",
      mt: "76",
      l: "630",
      id: "wc",
      gifW: "85",
      gifH: "68",
      gifT: "-1",
      gifL: "-8"
    },
    {
      link: "./assets/images/tents.png",
      gif: "./assets/images/icon_garazas_hover.gif",
      w: "96",
      h: "53",
      mt: "-74",
      l: "776",
      id: "tents",
      gifW: "95",
      gifH: "68",
      gifT: "-20",
      gifL: "0"
    },
    {
      link: "./assets/images/candy.png",
      gif: "./assets/images/icon_sldainis_hover.gif",
      w: "94",
      h: "52",
      mt: "17",
      l: "830",
      id: "candy",
      gifW: "85",
      gifH: "68",
      gifT: "-17",
      gifL: "2"
    },
    {
      link: "./assets/images/bird.png",
      gif: "./assets/images/icon_paukste_hover.gif",
      w: "88",
      h: "75",
      mt: "156",
      l: "800",
      id: "bird",
      gifW: "85",
      gifH: "68",
      gifT: "-5",
      gifL: "4"
    },
    {
      link: "./assets/images/church.png",
      gif: "./assets/images/icon_malda_hover.gif",
      w: "77",
      h: "77",
      mt: "-110",
      l: "960",
      id: "church",
      gifW: "85",
      gifH: "68",
      gifT: "-8",
      gifL: "-4"
    },
    {
      link: "./assets/images/excavator.png",
      gif: "./assets/images/icon_kamaz_hover.gif",
      w: "97",
      h: "69",
      mt: "70",
      l: "940",
      id: "excavator",
      gifW: "95",
      gifH: "68",
      gifT: "-17",
      gifL: "0"
    },
    {
      link: "./assets/images/hydrant.png",
      gif: "./assets/images/icon_kranas_hover.gif",
      w: "60",
      h: "69",
      mt: "156",
      l: "980",
      id: "hydrant",
      gifW: "85",
      gifH: "68",
      gifT: "-10",
      gifL: "-10"
    },
    {
      link: "./assets/images/bike.png",
      gif: "./assets/images/icon_dviratis_hover.gif",
      w: "81",
      h: "66",
      mt: "-144",
      l: "1076",
      id: "bike",
      gifW: "85",
      gifH: "68",
      gifT: "-9",
      gifL: "-1"
    },
    {
      link: "./assets/images/horse.png",
      gif: "./assets/images/icon_arklys_hover.gif",
      w: "91",
      h: "84",
      mt: "-24",
      l: "1040",
      id: "horse",
      gifW: "88",
      gifH: "68",
      gifT: "-10",
      gifL: "3"
    },
    {
      link: "./assets/images/egg.png",
      gif: "./assets/images/icon_kiausinis_hover.gif",
      w: "57",
      h: "72",
      mt: "86",
      l: "1090",
      id: "egg",
      gifW: "85",
      gifH: "68",
      gifT: "-7",
      gifL: "-13"
    },
    {
      link: "./assets/images/fatties.png",
      gif: "./assets/images/icon_gyventojai_hover.gif",
      w: "82",
      h: "77",
      mt: "-174",
      l: "1247",
      id: "fatties",
      gifW: "85",
      gifH: "68",
      gifT: "-11",
      gifL: "0"
    },
    {
      link: "./assets/images/mask.png",
      gif: "./assets/images/icon_kauke_hover.gif",
      w: "79",
      h: "70",
      mt: "36",
      l: "1233",
      id: "mask",
      gifW: "85",
      gifH: "68",
      gifT: "-6",
      gifL: "-4"
    },
    {
      link: "./assets/images/notebook.png",
      gif: "./assets/images/icon_dalia_hover.gif",
      w: "58",
      h: "76",
      mt: "136",
      l: "1194",
      id: "notebook",
      gifW: "85",
      gifH: "68",
      gifT: "-6",
      gifL: "-13"
    },
    {
      link: "./assets/images/skyscraper.png",
      gif: "./assets/images/icon_europa_hover.gif",
      w: "55",
      h: "130",
      mt: "216",
      l: "1120",
      id: "skyscraper",
      gifW: "130",
      gifH: "108",
      gifT: "0",
      gifL: "-39"
    }
  ];