import React, { useEffect, useState } from 'react';
import { StyledHolder, HolderStyles } from "../../styled/types";
import styled from "styled-components";
 
const TransitionHolder = styled(StyledHolder)`
    background-color: #db5c33;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    transition: opacity 400ms, transform 400ms ease-out;
    transform-style: preserve-3d !important;
    will-change: transform;
    opacity: 0;

    &.close {
        opacity: 1;
    }
    &.ready {
        transform: translate3d(100%,0,0);
    }
`;

interface PageTransitionProps extends HolderStyles { 
    pageReady: boolean
}

export default function PageTransition(props: PageTransitionProps): JSX.Element {

    const [close, setClose] = useState(false);

    useEffect(() => {
        setClose(true);
    }, [])

    return (
        <TransitionHolder className={(close ? " close": "") + (props.pageReady ? " ready" : "")}></TransitionHolder>
    )
}
