import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';

class Index extends Component {
  render() {
    return (
      <Provider store={store()}>
        <App />
      </Provider>
    )
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));