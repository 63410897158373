import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StyledHolder } from "../../styled/types";
import {
  OldGuyModal,
  SphynxModal,
  TextModal,
  VideoModal,
  GalleryModal,
} from "./";
import { Object, Objects, Picture } from "../../store/actions";

const StyledPopup = styled(StyledHolder)`
  .overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.42);
    z-index: 5;
    transition: opacity 400ms;
    opacity: 0;
    display: flex;
  }
  .right,
  .left,
  .pop,
  .bottom {
    transition: transform ease-out 600ms, opacity 600ms;
    transform-style: preserve-3d !important;
    will-change: transform, opacity;
    opacity: 0;
  }
  .pop {
    margin: auto;
    transform: translate3d(0, -50%, 0);
  }
  .right {
    transform: translate3d(50%, 0, 0);
    transition: transform ease-out 600ms 200ms, opacity 600ms 200ms;
  }
  .left {
    transform: translate3d(-50%, 0, 0);
    transition: transform ease-out 600ms 400ms, opacity 600ms 400ms;
  }
  .bottom {
    transform: translate3d(0, 50%, 0);
    transition: transform ease-out 600ms 600ms, opacity 600ms 600ms;
  }
  &.active {
    .overlay {
      opacity: 1;
    }
    .right,
    .left,
    .pop,
    .bottom {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`;

export enum PopupTypes {
  oldGuy,
  sphynx,
  text,
  video,
  gallery,
}

export interface PopupData {
  old_guy_table_texts?: [string, string, string, string];
  sphynx_guy_table_texts?: [string, string, string, string];
  id?: string;
  videoText?: string;
  key?: number;
  pictures?: Picture[];
}

interface PopupProps {
  popType: PopupTypes;
  popData: PopupData;
  redirectToMap: Function;
  objects?: Objects;
  closePop: Function;
}

let timer1: ReturnType<typeof setTimeout> | undefined;

export default function Popup(props: PopupProps): JSX.Element {
  const { popType = "", popData = {}, redirectToMap, objects = {} } = props;
  let object: Object | undefined;
  if (popData.id && objects[popData.id]) {
    object = objects[popData.id];
  }

  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => setActive(true));

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const closePop = (): void => {
    setActive(false);
    timer1 = setTimeout(() => props.closePop && props.closePop(), 401);
  };

  const handleOverlayClick = (e: React.MouseEvent): void => {
    const target = e.target as HTMLElement;
    if (target.classList.contains("modal")) closePop();
  };

  const returnPop = (): JSX.Element => {
    switch (popType) {
      case PopupTypes.oldGuy:
        return (
          <OldGuyModal
            data={popData.old_guy_table_texts || []}
            redirectToMap={redirectToMap}
          />
        );
      case PopupTypes.sphynx:
        return (
          <SphynxModal
            data={popData.sphynx_guy_table_texts || []}
            redirectToMap={redirectToMap}
          />
        );
      case PopupTypes.text:
        return <TextModal data={object} closePop={closePop} />;
      case PopupTypes.video:
        return <VideoModal data={object} closePop={closePop} />;
      case PopupTypes.gallery:
        return (
          <GalleryModal
            pictures={popData.pictures}
            index={popData.key}
            closePop={closePop}
          />
        );
      default:
        return (
          <OldGuyModal
            data={popData.old_guy_table_texts || []}
            redirectToMap={redirectToMap}
          />
        );
    }
  };

  return (
    <StyledPopup
      className={active ? " active" : ""}
      onClick={(e) => handleOverlayClick(e)}
    >
      <div className="overlay">{returnPop()}</div>
    </StyledPopup>
  );
}
