import { Dispatch } from 'redux';
import { ActionTypes } from '../types';

export interface Picture { 
  "big": string,
  "thumb": string,
  "name": string,
  "text": string
}

export interface Logo {
  "logo": string,
  "link": string
}

export interface HeaderLink {
  "name": string,
  "link": string,
  "src": string
}

export interface Object {
  video?: string,
  name?: string,
  text?: string,
  image?: string
  frame_desktop?: string,
  frame_mobile?: string
}

export interface Objects {[key: string]: Object}

export interface MapInterface { 
  logo?: string,
  text_video?: string,
  text_gallery?: string,
  text_contacts?: string,
  text_market?: string,
  video_text?: string
}

export interface VideoObject {
  name: string,
  video: string,
  text: string
}

export interface HeaderInterface {
  "languages": HeaderLink[],
  "socials": HeaderLink[]
}

export interface ContactsProps { 
  "logo"?: string,
  "heading"?: string,
  "company_name"?: string,
  "phone_image"?: string,
  "instagram_image"?: string,
  "email_image"?: string,
  "year"?: string,
  "phone"?: string,
  "instagram"?: string,
  "email"?: string
}

export interface GlobalData {
  "lang": string,
  "mobile": string,
  "intro": {
    "video": string,
    "forward_button": string
  },
  "header": HeaderInterface,
  "main": {
    "logo": string,
    "baloon_1": string,
    "baloon_2": string,
    "baloon_1_out": string,
    "baloon_2_out": string,
    "old_guy_table_texts": [string, string, string, string],
    "sphynx_guy_table_texts": [string, string, string, string]
  },
  "movie": {
    "logo": string,
    "heading": string,
    "videos": VideoObject[],
    "description": string
  },
  "gallery": {
    "logo": string,
    "heading": string,
    "pictures": Picture[]
  },
  "contacts": ContactsProps,
  "map": MapInterface,
  "objects": Objects,
  "sponsors": {
    "logo": string,
    "heading": string,
    "sponsors_text": string,
    "partners_text": string,
    "sponsors_logos": Logo[],
    "partners_logos": Logo[]
  },
  "team": {
    "logo": string,
    "heading": string,
    "team_name": string,
    "members": string[]
  },
  "marketLink": string
}

export interface FetchDataAction {
  type: ActionTypes.fetchData;
  payload: GlobalData
}

export const fetchData = () => async (dispatch: Dispatch) => { 
  let data: GlobalData | undefined;

  try {
    const dataObject = document.getElementById('json');
    if (dataObject) {
      data = JSON.parse(dataObject.innerHTML);
    }
  } catch(err) {
    console.log(err);
  }
  
  if (data) dispatch<FetchDataAction>({
    type: ActionTypes.fetchData,
    payload: data
  })
}