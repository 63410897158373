import styled, { css } from "styled-components";

// Mixins

export const hover = (content) => {
  if (!content) return "";
  return css`
    &:hover {
      @media (min-width: 1025px) {
        ${content}
      }
    }
  `;
};

export const touch = (content) => {
  if (!content) return "";
  return css`
    @media (max-width: 1024px) {
      ${content}
    }
  `;
};

export const mobile = (content) => {
  if (!content) return "";
  return css`
    @media (max-width: 1000px) {
      ${content}
    }
  `;
};

export const desktop = (content) => {
  if (!content) return "";
  return css`
    @media (min-width: 1001px) {
      ${content}
    }
  `;
};

export const scrollbar = () => {
  if (typeof window === "undefined") return 0;
  return (
    window.innerWidth - document.getElementsByTagName("html")[0].clientWidth
  );
};

export const placeholder = (content = "") => css`
  &::-webkit-input-placeholder {
    transition: transform 250ms, opacity 250ms, color 250ms;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    color: rgba(0, 0, 0, 0.3);
    ${content}
  }
  &:-moz-placeholder {
    transition: transform 250ms, opacity 250ms, color 250ms;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    color: rgba(0, 0, 0, 0.3);
    ${content}
  }
  &::-moz-placeholder {
    transition: transform 250ms, opacity 250ms, color 250ms;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    color: rgba(0, 0, 0, 0.3);
    ${content}
  }
  &:-ms-input-placeholder {
    transition: transform 250ms, opacity 250ms, color 250ms;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    color: rgba(0, 0, 0, 0.3);
    ${content}
  }
  &.error {
    &::-webkit-input-placeholder {
      color: red;
    }
    &:-moz-placeholder {
      color: red;
    }
    &::-moz-placeholder {
      color: red;
    }
    &:-ms-input-placeholder {
      color: red;
    }
  }
  &:focus::-webkit-input-placeholder {
    transform: translate3d(-15px, 0, 0);
    opacity: 0;
  }
  &:focus:-moz-placeholder {
    transform: translate3d(-15px, 0, 0);
    opacity: 0;
  }
  &:focus::-moz-placeholder {
    transform: translate3d(-15px, 0, 0);
    opacity: 0;
  }
  &:focus:-ms-input-placeholder {
    transform: translate3d(-15px, 0, 0);
    opacity: 0;
  }
`;

// Defaults

export const input = css`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  border: none;
  outline: none;
  width: 100%;
`;

export const Holder = styled.div`
  ${({ lh }) =>
    lh &&
    `
  line-height: ${lh};
  `}
  ${({ textAlign }) =>
    textAlign &&
    `
  text-align: ${textAlign};
  `}
${({ textColor }) =>
    textColor &&
    `
    color: ${textColor};
    `}
  ${({ alpha }) =>
    alpha &&
    `
      opacity: ${alpha};
    `}
    ${({ italic }) =>
    italic &&
    `
      font-style: italic;
    `}
  ${({ position }) =>
    position &&
    `
    position: ${position};
  `}
  ${({ inline }) =>
    inline &&
    `
    display: inline-block;
  `}
  ${({ fSize }) =>
    fSize &&
    `
      font-size: ${fSize / 10}rem;
      `}
  ${({ m }) =>
    m &&
    `
    margin: ${
      m.indexOf("%") === -1 && m.indexOf("auto") === -1 && m.indexOf(" ") === -1
        ? m / 10 + `rem`
        : m
    };
  `}
  ${({ mv }) =>
    mv &&
    `
    margin: ${
      mv.indexOf("%") === -1 && mv.indexOf("auto") === -1 ? mv / 10 + `rem` : mv
    } 0;
  `}
  ${({ mh }) =>
    mh &&
    `
    margin: 0 ${
      mh.indexOf("%") === -1 && mh.indexOf("auto") === -1 ? mh / 10 + `rem` : mh
    };
  `}
  ${({ mt }) =>
    mt &&
    `
    margin-top: ${
      mt.indexOf("%") === -1 && mt.indexOf("auto") === -1 ? mt / 10 + `rem` : mt
    };
  `}
  ${({ mb }) =>
    mb &&
    `
    margin-bottom: ${
      mb.indexOf("%") === -1 && mb.indexOf("auto") === -1 ? mb / 10 + `rem` : mb
    };
  `}
  ${({ ml }) =>
    ml &&
    `
    margin-left: ${
      ml.indexOf("%") === -1 && ml.indexOf("auto") === -1 ? ml / 10 + `rem` : ml
    };
  `}
  ${({ mr }) =>
    mr &&
    `
    margin-right: ${
      mr.indexOf("%") === -1 && mr.indexOf("auto") === -1 ? mr / 10 + `rem` : mr
    };
  `}
  ${({ p }) =>
    p &&
    `
    padding: ${p.indexOf("%") === -1 ? p / 10 + `rem` : p};
  `}
  ${({ pv }) =>
    pv &&
    `
    padding: ${pv.indexOf("%") === -1 ? pv / 10 + `rem` : pv} 0;
  `}
  ${({ ph }) =>
    ph &&
    `
    padding: 0 ${ph.indexOf("%") === -1 ? ph / 10 + `rem` : ph};
  `}
  ${({ pt }) =>
    pt &&
    `
    padding-top: ${pt.indexOf("%") === -1 ? pt / 10 + `rem` : pt};
  `}
  ${({ pb }) =>
    pb &&
    `
    padding-bottom: ${pb.indexOf("%") === -1 ? pb / 10 + `rem` : pb};
  `}
  ${({ pl }) =>
    pl &&
    `
    padding-left: ${pl.indexOf("%") === -1 ? pl / 10 + `rem` : pl};
  `}
  ${({ pr }) =>
    pr &&
    `
    padding-right: ${pr.indexOf("%") === -1 ? pr / 10 + `rem` : pr};
  `}
  ${({ maxw }) =>
    maxw &&
    `
    max-width: ${maxw.indexOf("%") === -1 ? maxw / 10 + `rem` : maxw};
  `}
  ${({ maxh }) =>
    maxh &&
    `
    max-height: ${
      maxh.indexOf("%") === -1 && maxh.indexOf("vh") === -1
        ? maxh / 10 + `rem`
        : maxh
    };
  `}
  ${({ minw }) =>
    minw &&
    `
    min-width: ${minw.indexOf("%") === -1 ? minw / 10 + `rem` : minw};
  `}
  ${({ minh }) =>
    minh &&
    `
    min-height: ${
      minh.indexOf("%") === -1 && minh.indexOf("vh") === -1
        ? minh / 10 + `rem`
        : minh
    };
  `}
  ${({ l }) =>
    l &&
    `
    left: ${l / 10}rem;
  `}
  ${({ t }) =>
    t &&
    `
    top: ${t.indexOf("%") === -1 ? t / 10 + `rem` : t};
  `}
  ${({ b }) =>
    b &&
    `
    bottom: ${b / 10}rem;
  `}
  ${({ z }) =>
    z &&
    `
    z-index: ${z};
  `}
  ${({ r }) =>
    r &&
    `
    right: ${r / 10}rem;
  `}
  ${({ w }) =>
    w &&
    `
    width: ${
      w.indexOf("%") !== -1 ||
      w.indexOf("auto") !== -1 ||
      w.indexOf("vw") !== -1
        ? w
        : w / 10 + "rem"
    };
  `}
  ${({ h }) =>
    h &&
    `
    height: ${
      h.indexOf("%") !== -1 ||
      h.indexOf("auto") !== -1 ||
      h.indexOf("vh") !== -1
        ? h
        : h / 10 + "rem"
    };
  `}
  ${({ radius }) =>
    radius &&
    `
    border-radius: ${radius / 10}rem;
  `}
  ${({ overflow }) =>
    overflow &&
    `
    overflow: ${overflow};
  `}
  ${({ bold }) =>
    bold &&
    `
    font-weight: bold;
  `}
  ${({ noEvents }) =>
    noEvents &&
    `
    pointer-events: none;
  `}
`;

// export const H1 = styled.h1`
//   font-size: 72px;
//   text-align: center;
//   font-family: var(--bold);
//   margin: 0 0 50px;
//   letter-spacing: 30px;
// `;

// export const H2 = styled.h2`
//   text-align: left;
//   font-family: var(--bold);
//   margin: 20px 0;
//   font-size: 32px;
//   line-height: 1.25;
// `;

// export const H3 = styled.h3`
//   text-align: left;
//   font-family: var(--bold);
//   margin: 20px 0;
//   font-size: 24px;
//   line-height: 1.33;
// `;

// export const H4 = styled.h4`
//   font-size: 20px;
//   text-align: left;
//   font-family: var(--bold);
//   margin: 20px 0;
// `;

// export const H5 = styled.h5`
//   font-size: 16px;
//   text-align: left;
//   font-family: var(--bold);
//   margin: 0 0 10px;
//   line-height: 1.25;
// `;

export const Normal = styled(Holder)`
  font-size: 23px;
  font-family: var(--no);
  line-height: 120%;

  &.white {
    color: #fff;
  }
  p {
    margin: 25px 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Content = styled(Holder)`
  h1 {
    font-size: 16px;
    font-weight: bold;
    line-height: ‭1.4;
    text-transform: uppercase;
    margin-top: 0;
    letter-spacing: 5px;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 11px;
    font-weight: bold;
    line-height: ‭1.4;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin: 30px 0 15px;
  }
  p {
    line-height: 15.6px;
    margin: 15px 0;
  }
  > * {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Flex = styled(Holder)`
  display: flex;

  ${({ align }) =>
    align &&
    `
    align-items: ${align};
  `}
  ${({ justify }) =>
    justify &&
    `
    justify-content: ${justify};
  `}
  ${({ inline }) =>
    inline &&
    `
    display: inline-flex;
  `}
  ${({ wrap }) =>
    wrap &&
    `
    flex-wrap: wrap;
  `}
  ${({ grow }) =>
    grow &&
    `
    > * {
      flex-grow: ${grow};
    }
  `}
  ${({ growSelf }) =>
    growSelf &&
    `
      flex-grow: ${growSelf};
  `}
  ${({ dir }) =>
    dir &&
    `
    flex-direction: ${dir};
  `}
  ${({ shrink }) =>
    shrink
      ? css`
          > * {
            flex-shrink: 1;
          }
        `
      : css`
          > * {
            flex-shrink: 0;
          }
        `}
`;

export const Grid = styled(Holder)`
  display: grid;

  ${({ inline }) =>
    inline &&
    `
    display: inline-grid;
  `}
  ${({ cols }) =>
    cols &&
    `
    grid-template-columns: ${cols};
  `}
  ${({ rows }) =>
    rows &&
    `
    grid-template-rows: ${rows};
  `}
  ${({ align }) =>
    align
      ? `
      align-items: ${align};
      `
      : `
      align-items: start;
      `}
  ${({ justify }) =>
    justify &&
    `
    justify-items: ${justify};
  `}
  ${({ gap }) =>
    gap &&
    `
    grid-gap: ${Number(gap) / 10}rem ${Number(gap) / 10}rem;
  `}
  ${({ gaph }) =>
    gaph &&
    `
    grid-column-gap: ${Number(gaph) / 10}rem;
  `}
  ${({ gapv }) =>
    gapv &&
    `
    grid-row-gap: ${Number(gapv) / 10}rem;
  `}
  ${({ flow }) =>
    flow &&
    `
    grid-auto-flow: ${flow};
  `}
  ${({ autoRows }) =>
    autoRows &&
    `
    grid-auto-rows: ${autoRows}px;
  `}
`;

export const button = css`
  display: inline-block;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  transition: background-color 250ms, opacity 250ms, color 250ms;
  user-select: none;
  font-family: var(--no);
`;

export const Clear = styled.div`
  clear: both;
`;

export const Wrapper = styled(Holder)`
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: auto;
  position: relative;
`;

export const AspectContainer = styled(Holder)`
  position: relative;
  max-height: 100vh;
  width: 192rem;
  height: 108rem;
  overflow: hidden;
`;

export const StyledModal = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .link {
    cursor: pointer;
  }
`;

export const Text = styled(Holder)`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 0 10rem;
  color: #fff;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ${({ black }) => black && "color: #000;"}
  ${({ orange }) => orange && "color: #db5c33;"}
  ${({ full }) => full && "padding: 0 0;"}
  font-size: ${({ fontSize }) => fontSize || "2rem"};
`;
