import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyledFlex, StyledHolder } from "../styled/types";
import { Image, SuperLink } from "../components/elements";
import styled, { keyframes, css } from "styled-components"; 
import { mobile } from "../styled";

const buttonAnimation = keyframes`
  0% {
    transform: translate3d(0,0,0) rotateZ(-5deg);
  }
  50% {
    transform: translate3d(0,0,0) rotateZ(5deg);
  }
  100% {
    transform: translate3d(0,0,0) rotateZ(-5deg);
  }
`;

const IntroPage = styled.div`
  .background_video {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &:after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      z-index: 3;
      top: 0;
      left: 0;
    }
    video {
      box-sizing: border-box;
      height: 56.25vw !important;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute !important;
      top: 50%;
      width: 177.77777778vh !important;
    }
  }
  .forward {
    will-change: transform;
    animation: 1s ${buttonAnimation} ease-out infinite;
    transform-origin: bottom center;
    transform-style: preserve-3d;

    ${mobile(css`
      width: 24.5rem;
      height: 27.9rem;
      bottom: 6rem;
      right: 5rem;
      position: fixed;
    `)}
  }
`;

interface IntroProps { 
  video?: string,
  forward_button?: string,
  closeScene: Function,
  pageReady: Function
}

class Intro extends Component<IntroProps> {

  componentDidMount(): void {
    this.props.pageReady && this.props.pageReady();
  }

  render() {
    const { video = "", forward_button = ""} = this.props;

    return (
      <IntroPage className="page black">
        <StyledFlex className="background_video" align="center" position="absolute" t="0" l="0">
          <video playsInline muted autoPlay loop>
            <source src={video} type="video/mp4"/>
          </video>
        </StyledFlex>
        <StyledHolder className="forward" w="245" h="279" r="25" b="60" z="4" position="absolute">
          <SuperLink to="/heros" closeScene={this.props.closeScene}>
            <Image  src={forward_button} filterHover={true} />   
          </SuperLink>
        </StyledHolder>
      </IntroPage>
    )
  }
};

const mapStateToProps = ({ store }: any): { store: IntroProps | {} } => store.intro || {}

export default connect(mapStateToProps)(Intro);