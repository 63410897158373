import React, { Component } from "react";
import { connect } from "react-redux";
import { StyledHolder, StyledGrid, StyledFlex } from "../styled/types";
import { Image, BackButton, Player, SuperLink } from "../components/elements";
import styled from "styled-components";
import { VideoObject } from "../store/actions";
import ReactHtmlParser from "react-html-parser";
import { Text } from "../styled";

const VideoPage = styled.div`
  padding: 17.5rem 0;
  display: block !important;
`;

interface VideoProps {
  logo?: string;
  heading?: string;
  videos?: VideoObject[];
  description?: string;
  closeScene: Function;
  pageReady: Function;
}

class Video extends Component<VideoProps> {
  state = {
    videoPlaying: "",
  };

  componentDidMount(): void {
    this.props.pageReady && this.props.pageReady();
  }

  startPlaying(id: string): void {
    if (!id) return;
    this.setState({ videoPlaying: id });
  }

  returnVideos(videos: VideoObject[] | undefined): JSX.Element[] | undefined {
    if (!videos || !Array.isArray(videos)) return;

    return videos.map(
      ({ name = "", video = "", text = "" }: VideoObject, i) => (
        <StyledFlex key={i} dir="column" align="center">
          <Player
            video={video}
            w="665"
            h="378"
            mb="20"
            position="relative"
            small={true}
            startOutsidePlayer={(id: string) => this.startPlaying(id)}
            currentlyPlaying={this.state.videoPlaying}
          />
          <Text>{ReactHtmlParser(text)}</Text>
        </StyledFlex>
      )
    );
  }

  render() {
    const { logo = "", heading = "", videos } = this.props;

    return (
      <VideoPage className="page yellow">
        <BackButton closeScene={this.props.closeScene} />
        <StyledHolder position="relative" mb="60">
          <Image position="static" src={heading} w="1920" h="166" />
          <SuperLink closeScene={this.props.closeScene} to="/">
            <Image src={logo} t="-132" l="780" w="300" h="148" />
          </SuperLink>
        </StyledHolder>
        <StyledGrid
          rows="auto"
          cols="minmax(0, 1fr) minmax(0, 1fr)"
          gapv="60"
          gaph="50"
          w="1366"
          m="0 auto"
        >
          {this.returnVideos(videos)}
        </StyledGrid>
      </VideoPage>
    );
  }
}

const mapStateToProps = ({ store }: any): { store: VideoProps | {} } =>
  store.movie || {};

export default connect(mapStateToProps)(Video);
