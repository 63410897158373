import React from 'react';
import { StyledHolder, HolderStyles } from "../../styled/types";
import { Image } from "../elements";
import styled from "styled-components";

const StyledClose = styled(StyledHolder)`
    cursor: pointer;
    position: absolute;
    z-index: 5;
`;

interface CloseProps extends HolderStyles { 
    onClick: any,
    white?: boolean
}

export default function Close(props: CloseProps) {
    return (
        <StyledClose {...props}>
            {props.white ? (
                <Image opacityHover={true} w="64" h="64" src="./assets/images/closeWhite.png" />
            ) : (
                <Image filterHover={true} w="104" h="105" src="./assets/images/close.png" />
            )}
        </StyledClose>
    )
}
