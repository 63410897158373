import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { StyledHolder } from "../../styled/types";

interface SuperImageProps {
  to?: string;
  children?: JSX.Element;
  closeScene: Function;
  goBack?: boolean;
}

let timer1: ReturnType<typeof setTimeout> | undefined;

export default function SuperLink(props: SuperImageProps): JSX.Element {
  const [active, setActive] = useState(false);
  const history = useHistory();

  const { to = "/", children, closeScene, goBack } = props;

  useEffect(() => {
    return (): void => {
      clearTimeout(timer1);
    };
  }, []);

  const handleClick = (): void => {
    closeScene && closeScene();
    timer1 = setTimeout(() => {
      if (goBack) history.goBack();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setActive(true);
    }, 400);
  };

  return active && !goBack ? (
    <Redirect push to={to} />
  ) : (
    <StyledHolder
      {...props}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      {children}
    </StyledHolder>
  );
}
