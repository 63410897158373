import React, { Component } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Image, SuperLink, BackButton } from "../components/elements";
import { AspectContainer, mobile } from "../styled";
import { StyledHolder } from "../styled/types";
import { PopupTypes, PopupData } from "../components/blocks/Popup";
import { imagesData } from "../mapData";
import { MapInterface } from "../store/actions";
import { Menu } from "../components/blocks";

const MapPage = styled.div`
  user-select: none;

  .baloon {
    pointer-events: none;
    z-index: 4;
    opacity: 0;
    transform: translate3d(0, 20%, 0);
    transition: opacity 400ms, transform 400ms ease-out;
    will-change: transform;
    transform-style: preserve-3d !important;

    &.active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      pointer-events: all;
    }
  }
  .object,
  .tooltip {
    cursor: pointer;
    transform-style: preserve-3d !important;
    z-index: 3;
  }
  .hovered {
    transition: opacity 200ms;
    opacity: 0;
  }
  ${mobile(css`
    .container {
      max-height: none;
      height: 125rem;
    }
  `)}
`;

interface ImageInterface {
  link: string;
  w: string;
  h: string;
  mt: string;
  l: string;
  id: string;
  gif: string;
  gifW: string;
  gifH: string;
  gifL: string;
  gifT: string;
}

interface MapProps extends MapInterface {
  openPop: (type: PopupTypes, data: PopupData) => void;
  marketLink?: string;
  closeScene: Function;
  pageReady: Function;
}

let debouncer = false;
let timer1: ReturnType<typeof setTimeout> | undefined;
let timer2: ReturnType<typeof setTimeout> | undefined;
let timer3: ReturnType<typeof setTimeout> | undefined;
let timer4: ReturnType<typeof setTimeout> | undefined;
let timer5: ReturnType<typeof setTimeout> | undefined;

class Map extends Component<MapProps> {
  state = {
    tooltipLeft: "0",
    tooltipMarginTop: "0",
    tooltipOpened: false,
    tooltipId: "",
    hovered: null,
  };

  componentWillUnmount(): void {
    debouncer = false;
    clearTimeout(timer1);
    clearTimeout(timer2);
    clearTimeout(timer3);
    clearTimeout(timer4);
    clearTimeout(timer5);
  }

  openTooltips(l: string, mt: string, tooltipId: string): void {
    if (isNaN(Number(l)) || isNaN(Number(mt)) || !tooltipId || debouncer)
      return;
    debouncer = true;
    clearTimeout(timer4);
    timer1 = setTimeout(() => {
      debouncer = false;
    }, 500);

    if (tooltipId === this.state.tooltipId) {
      clearTimeout(timer1);
      debouncer = false;
      return this.closeTooltips();
    }

    if (this.state.tooltipId !== "") {
      clearTimeout(timer1);
      debouncer = false;
      timer2 = setTimeout(() => this.openTooltips(l, mt, tooltipId), 501);
      return this.closeTooltips();
    }

    const tooltipMarginTop = String(Number(mt) - 316);
    const tooltipLeft = String(Number(l) - 240);

    this.setState(
      { tooltipLeft, tooltipMarginTop, tooltipId },
      () => (timer3 = setTimeout(() => this.setState({ tooltipOpened: true })))
    );
  }

  closeTooltips(): void {
    if (debouncer) return;
    debouncer = true;
    clearTimeout(timer1);
    timer4 = setTimeout(() => {
      debouncer = false;
    }, 500);

    this.setState(
      { tooltipOpened: false },
      () => (timer5 = setTimeout(() => this.setState({ tooltipId: "" }), 401))
    );
  }

  returnImages(imagesData: ImageInterface[]): JSX.Element[] {
    return imagesData.map(
      (
        {
          link = "",
          w = "",
          h = "",
          mt = "",
          l = "",
          id = "",
          gif = "",
          gifW = "",
          gifH = "",
          gifT = "",
          gifL = "",
        }: ImageInterface,
        i
      ) => {
        return (
          <StyledHolder
            className="object"
            key={i}
            position="absolute"
            l={l}
            w={w}
            h={h}
            mt={mt}
            t="50%"
            onClick={() => this.openTooltips(l, mt, id)}
            onMouseEnter={(e: any) => {
              if (window.innerWidth < 1025) return e.preventDefault();
              this.setState({ hovered: id });
            }}
            onMouseLeave={(e: any) => {
              if (window.innerWidth < 1025) return e.preventDefault();
              this.setState({ hovered: null });
            }}
          >
            <Image
              src={link}
              w={w}
              h={h}
              imgNoEvents={true}
              className={this.state.hovered === id ? " hovered" : ""}
            />
            <Image
              noEvents={true}
              src={this.state.hovered === id ? gif : ""}
              className={this.state.hovered === id ? "" : " no_src"}
              w={gifW}
              h={gifH}
              t={gifT}
              l={gifL}
            />
          </StyledHolder>
        );
      }
    );
  }

  handleAreaClick(e: React.MouseEvent): void {
    const target = e.target as HTMLElement;

    if (
      target.classList.contains("baloon") ||
      target.classList.contains("tooltip") ||
      target.classList.contains("object")
    )
      return;

    this.closeTooltips();
  }

  handleImageClick(type: PopupTypes, data: PopupData): void {
    this.closeTooltips();
    this.props.openPop && this.props.openPop(type, data);
  }

  render() {
    const { logo = "", video_text = "" } = this.props;

    return (
      <MapPage className="page grey">
        <BackButton closeScene={this.props.closeScene} />
        <AspectContainer
          className="container"
          onClick={(e) => this.handleAreaClick(e)}
        >
          <Image
            detectLoad={() => this.props.pageReady && this.props.pageReady()}
            src="./assets/images/mapBackground.jpg"
            w="1920"
            h="1080"
            mt="-540"
            t="50%"
            noEvents={true}
          />
          <SuperLink closeScene={this.props.closeScene} to="/">
            <Image src={logo} t="50%" l="781" w="438" h="183" mt="-466" />
          </SuperLink>
          {imagesData && this.returnImages(imagesData)}
          <StyledHolder
            className={"baloon" + (this.state.tooltipOpened ? " active" : "")}
            position="absolute"
            l={this.state.tooltipLeft}
            mt={this.state.tooltipMarginTop}
            t="50%"
            w="581"
            h="343"
          >
            <StyledHolder
              className="tooltip"
              position="absolute"
              onClick={() =>
                this.handleImageClick(PopupTypes.text, {
                  id: this.state.tooltipId,
                })
              }
              w="273"
              h="288"
              mt="-144"
              t="50%"
              l="0"
            />
            <StyledHolder
              className="tooltip"
              position="absolute"
              onClick={() =>
                this.handleImageClick(PopupTypes.video, {
                  id: this.state.tooltipId,
                  videoText: video_text,
                })
              }
              w="316"
              h="310"
              mt="-155"
              t="50%"
              r="0"
            />
            <Image
              className="tooltip"
              noEvents={true}
              src="./assets/images/tooltipOldGuy.png"
              w="273"
              h="288"
              t="30"
              l="0"
            />
            <Image
              className="tooltip"
              noEvents={true}
              src="./assets/images/tooltipSphynx.png"
              w="316"
              h="310"
              t="10"
              l="250"
            />
          </StyledHolder>
          <Menu
            closeScene={this.props.closeScene}
            marketLink={this.props.marketLink}
          />
          <Image
            src="./assets/images/COPYRIGHT.png"
            t="50%"
            l="1471"
            w="378"
            h="183"
            mt="351"
          />
        </AspectContainer>
      </MapPage>
    );
  }
}

const mapStateToProps = ({ store }: any): MapInterface | {} => store.map || {};

export default connect(mapStateToProps)(Map);
