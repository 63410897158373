import React from 'react';
import { StyledHolder, HolderStyles } from "../../styled/types";
import styled, { css } from "styled-components";
import { hover } from "../../styled";

const StyledImage = styled(StyledHolder)`
    .image {
        width: 100%;
    }
    .border {
        width: calc(100% + 20px);
        height: calc(100% + 16px);
        position: absolute;
        top: -8px;
        left: -13px;
    }
    ${({filterHover}) => filterHover && css`
        .image {
            transition: filter 400ms ease-out;
            will-change: filter;
        }
        ${hover(css`
            .image {
                filter: grayscale(100%);
            }
        `)}
    `}
    ${({opacityHover}) => opacityHover && css`
        .image {
            transition: opacity 400ms;
        }
        ${hover(css`
            .image {
                opacity: 0.8;
            }
        `)}
    `}
`;
  
interface ImageProps extends HolderStyles { 
    src: string,
    onClick?: any,
    imgNoEvents?: boolean,
    cover?: boolean,
    contain?: boolean,
    border?: boolean,
    detectLoad?: Function,
    filterHover?: boolean,
    opacityHover?: boolean
}

export default function Image(props: ImageProps): JSX.Element {
    const { src = "", position, w, imgNoEvents, cover, border, contain, detectLoad, noEvents } = props;

    return (
        <StyledImage {...props} position={position ? position : 'absolute'} w={w ? w : '100%'}>
            <img className="image" style={{ 
                pointerEvents: imgNoEvents || noEvents ? 'none' : 'all',
                objectFit: cover ? 'cover' : contain ? 'contain' : 'fill',
                height: cover || contain ? '100%' : 'auto'
            }} src={src} alt="" onLoad={() => detectLoad && detectLoad()} />
            {border && <img className="border" src="./assets/images/imageBorder.png" alt="" /> }
        </StyledImage>
    )
}
