import React from "react";
import { StyledFlex } from "../../styled/types";
import { Image } from "../elements";
import { HeaderInterface, HeaderLink } from "../../store/actions/data_actions";
import styled from "styled-components";

const Container = styled(StyledFlex)`
  .lang {
    display: inline-block;
    margin-right: 2.5rem;
  }
  .socials {
    justify-self: flex-end;
  }
  .social {
    display: inline-block;
    margin-left: 2rem;
  }
`;

interface HeaderProps {
  headerData?: HeaderInterface;
  lang: string;
}

export default function Header({ headerData, lang }: HeaderProps): JSX.Element {
  if (!headerData) return <></>;

  const { languages, socials } = headerData;

  const returnLangs = (languages: HeaderLink[]) => {
    if (!Array.isArray(languages) || languages.length < 2) return;

    return languages.map(({ name = "", link = "", src = "" }) => (
      <a
        href={link}
        className={"lang" + (lang === name ? " current" : "")}
        key={name}
      >
        <Image
          src={src}
          w="120"
          h="120"
          filterHover={true}
          cover={true}
          position="static"
        />
      </a>
    ));
  };

  const returnSocials = (socials: HeaderLink[]) => {
    if (!Array.isArray(socials)) return;

    return socials.map(({ name = "", link = "", src = "" }) => (
      <a
        href={link}
        className="social"
        key={name}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src={src}
          w="65"
          h="65"
          filterHover={true}
          cover={true}
          position="static"
        />
      </a>
    ));
  };

  return (
    <Container
      position="fixed"
      w="100%"
      h="172"
      t="0"
      l="0"
      ph="60"
      justify="space-between"
      z="2"
    >
      <StyledFlex align="flex-end">{returnLangs(languages)}</StyledFlex>
      <StyledFlex align="center" className="socials">
        {returnSocials(socials)}
      </StyledFlex>
    </Container>
  );
}
