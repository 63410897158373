import React, { Component } from "react";
import { connect } from "react-redux";
import {
  StyledHolder,
  StyledGrid,
  StyledFlex,
  StyledText,
} from "../styled/types";
import { Image, BackButton, SuperLink } from "../components/elements";
import styled from "styled-components";
import { Picture } from "../store/actions";
import ReactHtmlParser from "react-html-parser";
import { PopupTypes, PopupData } from "../components/blocks/Popup";

const GalleryPage = styled.div`
  padding: 17.5rem 0;
  display: block !important;

  .picture {
    cursor: pointer;
  }
`;

interface GalleryProps {
  logo?: string;
  heading?: string;
  pictures?: Picture[];
  openPop: (type: PopupTypes, data: PopupData) => void;
  closeScene: Function;
  pageReady: Function;
}

class Gallery extends Component<GalleryProps> {
  componentDidMount(): void {
    this.props.pageReady && this.props.pageReady();
  }

  returnPictures(pictures: Picture[] | undefined): JSX.Element[] | undefined {
    if (!pictures || !Array.isArray(pictures)) return;

    return pictures.map(({ thumb = "", name = "", text = "" }: Picture, i) => (
      <StyledFlex className="picture" key={i} dir="column" align="center">
        <StyledHolder
          w="472"
          h="295"
          position="relative"
          onClick={() =>
            this.props.openPop(PopupTypes.gallery, { key: i, pictures })
          }
        >
          <Image
            w="472"
            h="295"
            position="relative"
            src="./assets/images/videoBorderSmall.png"
          />
          <Image
            filterHover={true}
            w="429"
            h="233"
            t="15"
            l="20"
            src={thumb}
            cover={true}
          />
        </StyledHolder>
        <StyledText
          orange={true}
          full={true}
          fontSize="4rem"
          textAlign="center"
          mb="20"
        >
          {name}
        </StyledText>
        <StyledText black={true} full={true}>
          {ReactHtmlParser(text)}
        </StyledText>
      </StyledFlex>
    ));
  }

  render() {
    const { logo = "", heading = "", pictures } = this.props;

    return (
      <GalleryPage className="page brown">
        <BackButton closeScene={this.props.closeScene} />
        <StyledHolder position="relative" mb="100">
          <Image position="static" src={heading} w="1920" h="166" />
          <SuperLink closeScene={this.props.closeScene} to="/">
            <Image src={logo} t="-132" l="780" w="300" h="148" />
          </SuperLink>
        </StyledHolder>
        <StyledGrid
          rows="auto"
          cols="repeat(3, minmax(0, 1fr))"
          gapv="60"
          gaph="100"
          w="1502"
          m="0 auto"
        >
          {this.returnPictures(pictures)}
        </StyledGrid>
      </GalleryPage>
    );
  }
}

const mapStateToProps = ({ store }: any): { store: GalleryProps | {} } =>
  store.gallery || {};

export default connect(mapStateToProps)(Gallery);
