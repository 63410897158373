import React, { Component } from "react";
import GlobalStyle from "./styled/global";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Intro,
  Home,
  Map,
  Video,
  Gallery,
  Sponsors,
  Contacts,
  Team,
} from "./pages";
import { fetchData, Objects, HeaderInterface } from "./store/actions";
import { PagesTransition } from "./components/blocks";
import Popup, { PopupTypes, PopupData } from "./components/blocks/Popup";
import styled, { css } from "styled-components";
import { StyledFlex } from "./styled/types";
import { mobile } from "./styled";
import { Image } from "./components/elements";

const MobileVertical = styled(StyledFlex)`
  display: none;

  @media (orientation: portrait) {
    ${mobile(css`
      display: flex;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background: #fff;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 12rem;

      .inner {
        flex-shrink: 1;
        margin: auto;
        padding: 20rem;
        text-align: center;
        max-width: 165rem;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        color: #db5c33;
      }
    `)}
  }
`;

interface AppProps {
  dispatch: Function;
  lang?: string;
  objects?: Objects;
  mobile?: string;
  marketLink?: string;
  header?: HeaderInterface;
}

interface AppState {
  popOpen: boolean;
  popType: PopupTypes;
  popData: PopupData | {};
  redirectToMap: boolean;
  closeScene: boolean;
  pageReady: boolean;
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.props.dispatch(fetchData());

    this.state = {
      popOpen: false,
      popType: PopupTypes.oldGuy,
      popData: {},
      redirectToMap: false,
      closeScene: true,
      pageReady: false,
    };
  }

  openPop(type: PopupTypes, data: PopupData): void {
    this.setState({ popOpen: true, popType: type, popData: data });
    document.getElementsByTagName("html")[0].classList.add("open");
    document.getElementsByTagName("body")[0].classList.add("open");
  }

  closePop(): void {
    this.setState({ popOpen: false });
    document.getElementsByTagName("html")[0].classList.remove("open");
    document.getElementsByTagName("body")[0].classList.remove("open");
  }

  redirectToMap(): void {
    this.setState({ redirectToMap: true }, () => {
      this.setState({ redirectToMap: false });
      setTimeout(() => this.closePop());
    });
  }

  closeScene(): void {
    this.setState({ closeScene: true });
  }

  pageReady(): void {
    this.setState({ pageReady: true }, () => {
      setTimeout(() => {
        this.setState({ closeScene: false, pageReady: false });
      }, 300);
    });
  }

  render() {
    if (!this.props.lang) return <div></div>;

    return (
      <>
        <GlobalStyle />
        <main>
          <div id="scroller">
            <Router>
              <Switch>
                <Route path="/heros" exact>
                  <Home
                    lang={this.props.lang}
                    openPop={(type: PopupTypes, data: PopupData) =>
                      this.openPop(type, data)
                    }
                    redirectToMap={this.state.redirectToMap}
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                    headerData={this.props.header}
                    marketLink={this.props.marketLink}
                  />
                </Route>
                <Route path="/map" exact>
                  <Map
                    openPop={(type: PopupTypes, data: PopupData) =>
                      this.openPop(type, data)
                    }
                    marketLink={this.props.marketLink}
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                  />
                </Route>
                <Route path="/video" exact>
                  <Video
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                  />
                </Route>
                <Route path="/gallery" exact>
                  <Gallery
                    openPop={(type: PopupTypes, data: PopupData) =>
                      this.openPop(type, data)
                    }
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                  />
                </Route>
                <Route path="/sponsors" exact>
                  <Sponsors
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                  />
                </Route>
                <Route path="/contacts" exact>
                  <Contacts
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                  />
                </Route>
                <Route path="/team" exact>
                  <Team
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                  />
                </Route>
                <Route path="/">
                  <Intro
                    closeScene={() => this.closeScene()}
                    pageReady={() => this.pageReady()}
                  />
                </Route>
              </Switch>
            </Router>
          </div>
        </main>
        {this.state.popOpen && (
          <Popup
            popType={this.state.popType}
            popData={this.state.popData}
            objects={this.props.objects}
            redirectToMap={() => this.redirectToMap()}
            closePop={() => this.closePop()}
          />
        )}
        {this.state.closeScene && (
          <PagesTransition pageReady={this.state.pageReady} />
        )}
        <MobileVertical>
          <div className="inner">
            <Image
              src="./assets/images/rotate.svg"
              w="300"
              h="400"
              mb="50"
              position="relative"
            />
            <div>{this.props.mobile || ""}</div>
          </div>
        </MobileVertical>
      </>
    );
  }
}

const mapStateToProps = (state: any): { store: AppProps | {} } =>
  state.store || {};

const connectedApp = connect(mapStateToProps)(App);

export default process.env.NODE_ENV === "development"
  ? hot(module)(connectedApp)
  : connectedApp;
