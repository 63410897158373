import React from 'react';
import { Image } from "../elements";
import { StyledModal } from "../../styled";

interface SphynxModalProps {
    data: [string, string, string, string] | [],
    redirectToMap: Function
}

export default function SphynxModal(props: SphynxModalProps): JSX.Element {
    const { data = [], redirectToMap } = props;

    return (
        <StyledModal className="modal">
            <Image w="1283" h="861" className="pop" src="./assets/images/yellow_pop_background.png" />
            <Image className="bottom" w="754" h="820" mt="-350" t="50%" r="273" src="./assets/images/sphynx_head.png" />
            <Image className="left" w="770" h="58" mt="-340" t="50%" l="365" src={data[0] || ""} />
            <Image className="right" w="623" h="207" mt="-250" t="50%" l="425" src={data[1] || ""} />
            <Image className="left" w="464" h="158" mt="30" t="50%" l="475" src={data[2] || ""} />
            <Image opacityHover={true} className="right link" onClick={() => {if (redirectToMap) redirectToMap()}} w="240" h="42" mt="250" t="50%" l="585" src={data[3] || ""} />
        </StyledModal>
    )
}
