import { combineReducers } from "redux";
import dataReducer from './data_reducer';
import { GlobalData } from "../actions/data_actions";

export interface StoreState {
  store: GlobalData | {}
}

const rootReducer = combineReducers<StoreState>({ store: dataReducer });

export default rootReducer;
