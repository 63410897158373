import React from "react";
import { Image, Close, Player } from "../elements";
import { StyledModal, mobile } from "../../styled";
import { Object } from "../../store/actions";
import { StyledHolder } from "../../styled/types";
import styled, { css } from "styled-components";

const Modal = styled(StyledModal)`
  ${mobile(css`
    .close > * {
      width: 9.4rem;
      height: 10.4rem;
    }
  `)}
`;

interface VideoModalProps {
  data?: Object;
  closePop: Function;
}

export default function VideoModal(props: VideoModalProps): JSX.Element {
  const { data = {} }: VideoModalProps = props;
  const { video = "" } = data;

  return (
    <Modal className="modal">
      <StyledHolder>
        <Image
          w="1211"
          h="676"
          className="pop"
          position="relative"
          src="./assets/images/videoBorder.png"
        />
        <Player
          className="bottom"
          video={video}
          position="absolute"
          mt="-291"
          t="50%"
          l="435"
          w="1041"
          h="498"
        />
        <Close
          white={true}
          className="close"
          t="50%"
          l="1370"
          mt="-270"
          onClick={props.closePop}
        />
      </StyledHolder>
    </Modal>
  );
}
