import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyledHolder, StyledFlex } from "../styled/types";
import { Image, BackButton, SuperLink } from "../components/elements";
import styled from "styled-components";
import { ContactsProps as ContactsObject } from "../store/actions";

const ContactsPage = styled.div`
  padding: 17.5rem 0;
  display: block !important;
`;

interface ContactsProps extends ContactsObject {
  closeScene: Function,
  pageReady: Function
}

class Contacts extends Component<ContactsProps> {

  componentDidMount(): void {
    this.props.pageReady && this.props.pageReady();
  }

  render() {

    const { 
        logo = "",
        heading = "",
        company_name = "",
        phone_image = "",
        instagram_image = "",
        email_image = "",
        year = "",
        phone = "",
        instagram = "",
        email = "",
    } = this.props;

    return (
      <ContactsPage className="page red">
            <BackButton closeScene={this.props.closeScene} />
            <StyledHolder position="relative" mb="100">
                <Image position="static" src={heading} w="1920" h="166" />
                <SuperLink closeScene={this.props.closeScene} to="/">
                  <Image src={logo} t="-132" l="700" w="500" h="246" />
                </SuperLink>
            </StyledHolder>
            <StyledFlex dir="column" align="center">
                {company_name && <Image src={company_name} position="static" h="69" mb="30" contain={true} />}
                {phone_image && 
                  <a href={`tel:${phone}`}>
                    <Image opacityHover={true} src={phone_image} position="static" h="46" mb="30" contain={true} />
                  </a>
                }
                {instagram_image && 
                  <a href={instagram} target="_blank" rel="noreferrer">
                    <Image opacityHover={true} src={instagram_image} position="static" h="54" mb="30"contain={true} />
                  </a>
                }
                {email_image && 
                  <a href={`mailto:${email}`}>
                    <Image opacityHover={true} src={email_image} position="static" h="49" mb="30" contain={true} />
                  </a>
                }
                {year && <Image src={year} position="static" h="52" contain={true} />}
            </StyledFlex>
      </ContactsPage>
    )
  }
};

const mapStateToProps = ({ store }: any): { store: ContactsProps | {} } => store.contacts || {}

export default connect(mapStateToProps)(Contacts);