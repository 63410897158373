import { GlobalData, FetchDataAction } from "../actions/data_actions";
import { ActionTypes } from "../types";

const dataReducer = (state: GlobalData | {} = {}, action: FetchDataAction) => {
  switch (action.type) { 
    case ActionTypes.fetchData:
      return action.payload;
    default:
      return state
  }
}

export default dataReducer;