import { Holder, Flex, Grid, Text } from "./";
import styled from "styled-components";

export type HolderStyles = {
  position?: string;
  w?: string;
  h?: string;
  r?: string;
  b?: string;
  t?: string;
  l?: string;
  z?: string;
  maxh?: string;
  className?: string;
  textAlign?: string;
  mt?: string;
  ml?: string;
  mr?: string;
  mb?: string;
  ph?: string;
  m?: string;
  inline?: boolean;
  maxw?: string;
  filterHover?: boolean;
  opacityHover?: boolean;
  noEvents?: boolean;
};

type FlexOnlyStyles = {
  align?: string;
  dir?: string;
  justify?: string;
};
export type FlexStyles = HolderStyles & FlexOnlyStyles;

type GridStylesOnly = {
  gap?: string;
  cols?: string;
  rows?: string;
  flow?: string;
  gaph?: string;
  gapv?: string;
};
export type GridStyles = HolderStyles & GridStylesOnly;

type TextStylesOnly = {
  black?: boolean;
  full?: boolean;
  orange?: boolean;
  fontSize?: string;
};
export type TextStyles = HolderStyles & TextStylesOnly;

export const StyledHolder = styled(Holder)<HolderStyles>``;
export const StyledFlex = styled(Flex)<FlexStyles>``;
export const StyledGrid = styled(Grid)<GridStyles>``;
export const StyledText = styled(Text)<TextStyles>``;
