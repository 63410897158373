import React, { useEffect } from "react";
import { Image, Close } from "../elements";
import { StyledModal } from "../../styled";
import { Picture } from "../../store/actions";
import { StyledHolder, StyledText } from "../../styled/types";
import { Swiper, SwiperSlide } from "swiper/react";
import swiperStyles from "../../styled/swiper";
import styled from "styled-components";

const StyledGallery = styled(StyledModal)`
  user-select: none;
  ${swiperStyles}

  .swiper-container {
    width: 139.9rem;
    height: 78.1rem;
    overflow: visible;
    cursor: grab;
  }
  .button {
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
  }
`;

interface GalleryModalProps {
  index?: number;
  closePop: Function;
  pictures?: Picture[];
}

let swiperInstance: any = null;

export default function GalleryModal(props: GalleryModalProps): JSX.Element {
  const { pictures, index = 0 }: GalleryModalProps = props;

  useEffect(() => {
    return (): void => {
      swiperInstance = null;
    };
  }, []);

  const goBack = (): void => {
    if (!swiperInstance) return;
    swiperInstance.slidePrev(400, false);
  };

  const goForward = (): void => {
    if (!swiperInstance) return;
    swiperInstance.slideNext(400, false);
  };

  const returnSlides = (
    pictures: Picture[] | undefined
  ): JSX.Element[] | undefined => {
    if (!pictures || !Array.isArray(pictures)) return;

    return pictures.map(
      ({ big = "", name = "" }: Picture, i): JSX.Element => (
        <SwiperSlide key={i}>
          <StyledHolder className="grab" position="relative">
            <Image
              w="1399"
              h="781"
              position="relative"
              src="./assets/images/videoBorder.png"
            />
            <Image w="1154" h="645" t="45" l="120" cover={true} src={big} />
            {pictures.length > 1 && (
              <>
                {i ? (
                  <StyledHolder
                    className="button"
                    t="50%"
                    mt="-72"
                    l="-90"
                    z="3"
                    onClick={goBack}
                  >
                    <Image
                      filterHover={true}
                      w="173"
                      h="144"
                      position="relative"
                      src="./assets/images/arrowLeft.jpg"
                    />
                  </StyledHolder>
                ) : null}
                {pictures.length - 1 !== i && (
                  <StyledHolder
                    className="button"
                    t="50%"
                    mt="-72"
                    r="-90"
                    z="3"
                    onClick={goForward}
                  >
                    <Image
                      filterHover={true}
                      w="173"
                      h="144"
                      position="relative"
                      src="./assets/images/arrowRight.jpg"
                    />
                  </StyledHolder>
                )}
              </>
            )}
            <Close t="35" r="60" onClick={props.closePop} />
            <StyledText
              black={true}
              full={true}
              position="absolute"
              w="100%"
              textAlign="center"
              fontSize="3rem"
              b="37"
            >
              {name}
            </StyledText>
          </StyledHolder>
        </SwiperSlide>
      )
    );
  };

  const init = (swiper: any): void => {
    if (swiperInstance) return;
    swiperInstance = swiper;
    swiperInstance.slideTo(index, 0, false);
  };

  return (
    <StyledGallery className="modal pop">
      <Swiper
        spaceBetween={500}
        slidesPerView={1}
        onSwiper={(swiper) => init(swiper)}
      >
        {returnSlides(pictures)}
      </Swiper>
    </StyledGallery>
  );
}
